<template>
  <div class="upload">
    <div class="img" v-for="(item, index) in attach" :key="index" @click="viewImage(index, item)">
      <img :src="item.icon" />
      <img class="del-icon" :src="delIcon" alt="" @click.stop="delImage(index, item)" v-if="readonly == false" />
      <!-- <div class="file-name">hello world...jpg</div> -->
    </div>

    <div class="add-image" @click="uploadImg" v-if="isUploading == false && readonly == false">
      <img :src="addIcon" alt="" />

      <input multiple ref="image" type="file" name="" id="fileInput" style="display: none" @change="handleUpload" />
    </div>
    <div class="add-progress" v-show="isUploading">
      {{ totalPercent }}
    </div>
  </div>
</template>

<script>
import { fileValidate } from "@/util/fileValidate";
import { cosApi } from "@/api/cosApi.js";
import COS from "cos-js-sdk-v5"; //脚手架安装
import { nanoid } from "nanoid";
import msgLogger from "@/util/msgLogger";
import AWS from "aws-sdk";
import { ImagePreview } from "vant";

export default {
  components: {},
  model: {
    prop: "attach",
    event: "onAddMedia",
  },
  props: {
    attach: Array,
    readonly: Boolean,
    isSingleFile: Boolean,
    maxSize: Number,
    bizType: String,
  },
  data() {
    return {
      addIcon: require("@/assets/images/upload.png"),
      delIcon: require("@/assets/images/del-img.png"),
      videoUploadDoneIcon: require("@/assets/images/video-upload-done.png"),
      audioUploadDoneIcon: require("@/assets/images/audio-upload-done.png"),
      docUploadDoneIcon: require("@/assets/images/doc-upload-done.png"),
      zipUploadDoneIcon: require("@/assets/images/zip-upload-done.png"),

      bucket: "",
      region: "",
      fileDoneCnt: 0,
      cosStsUrl: "",

      isUploading: false,
      todoFiles: [], //上传和接收回调
      totalPercent: 0,
    };
  },
  methods: {
    //删除图片
    delImage(index, item) {
      //console.log(index, item);
      let retList = [...this.attach];
      let delItemList = retList.splice(index, 1);
      console.log("del item", delItemList);
      if (delItemList && delItemList.length > 0) {
        for (let item of delItemList) {
          cosApi.deleteCosByYou(item.cosId).then((ret) => {
            console.log("delete cos tmp", ret);
          });
        }
      }
      this.$emit("onAddMedia", retList); //emit 事件，父组件更新，再传给子组件
      this.$emit("update-attach", retList);
    },
    viewImage(index, item) {
      //      window.open(item.url, "_blank");
      if (item.fileType == "image") {
        ImagePreview({ images: [item.url], closeable: true });
      } else {
        msgLogger.done("文件已经上传");
      }
    },
    getBucketRegion() {
      cosApi
        .getCosBucketRegion("private")
        .then((ret) => {
          console.log("getBucket", ret);
          let data = ret.data;
          this.bucket = data.bucket;
          this.region = data.region;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCosStsUrl() {
      const url = cosApi.getCosStsUrl();
      this.cosStsUrl = url;
    },
    // 点击请求input的click()事件
    uploadImg() {
      this.$refs.image.click();
    },
    // 用户选择好文件
    async handleUpload(e) {
      let _this = this;
      console.log(e.target.files, "上传文件");
      //this.getCosPrefix(); 在mounted初始化就行了，不需要再取一次了

      let fileList = [];
      this.todoFiles = [];
      const maxsize = 3000 * 1024 * 1024; // 3gb
      let userSelectFiles = e.target.files;
      if (this.isSingleFile && userSelectFiles && userSelectFiles.length > 1) {
        msgLogger.warn("只能上传一个文件");
        this.isUploading = false;
        return;
      }
      if (this.attach && this.attach.length >= this.maxSize) {
        msgLogger.warn("最大上传数量：" + this.maxSize);
        this.isUploading = false;
        return;
      }

      for (let i = 0; i < userSelectFiles.length; i++) {
        if (!fileValidate.matchFileType(userSelectFiles[i].name)) {
          msgLogger.error("文件格式错误，请重新上传!");
          return;
        }
        if (userSelectFiles[i].size > maxsize) {
          msgLogger.error("文件大小错误，请重新上传!");
          return;
        }
        fileList.push(userSelectFiles[i]);
        let fileName = userSelectFiles[i].name;
        let ret = null;
        let err = null;
        [ret, err] = await cosApi.awaitChain(cosApi.getTmpCosObject(this.bizType, fileName));
        if (ret && ret.code == 0) {
          let cosData = ret.data;
          this.todoFiles.push({
            cosId: cosData.cosId,
            file: userSelectFiles[i],
            cosKey: cosData.cosKey,
            keyPrefix: cosData.keyPrefix,
            fileType: cosData.fileType,
            fileName: cosData.fileName,
            cloudService: 0, // 0 cos 1 doge
            vid: 0,
            vcode: "",
            did: "",
            percent: 0,
            url: "",
            key: "",
            icon: "",
          });
        } else {
          return;
        }
      }

      console.log("todofiles....", this.todoFiles);
      this.isUploading = true;
      //逐个上传
      for (let i = 0; i < this.todoFiles.length; i++) {
        // let file = this.folderFiles[i];
        // let fileName = this.folderFiles[i].name;
        // let webkitRelativePath = this.folderFiles[i].webkitRelativePath;
        console.log("...upload one file", this.todoFiles[i]);
        // 分片上传文件
        let _this = this;
        if (this.todoFiles[i].fileType == "video") {
          this.dogeUploadSingleVod(this.todoFiles[i]); // 视频统一传到doge cloud
        } else {
          this.cosUploadSingle(this.todoFiles[i]);
        }
      }
    },

    cosUploadSingle(afile) {
      let _this = this;
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          //const COS_STS_URL = `/api/itedu/v1/cos/appid`;
          console.log("sts url", _this.cosStsUrl);
          var url = _this.cosStsUrl;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });

      const slice = cosClient.sliceUploadFile(
        {
          Bucket: this.bucket,
          Region: this.region,
          Key: afile.cosKey,
          Body: afile.file,
          onTaskReady: function(taskId) {
            /* 非必须 */
            console.log(taskId);
          },
          onHashProgress: function(progressData) {
            /* 非必须 */
            console.log(JSON.stringify(progressData));
          },
          onProgress: function(progressData) {
            /* 非必须 */
            console.log(JSON.stringify(progressData));
            afile.percent = progressData.percent.toFixed(2) * 100;
            //console.log("cos", afile.percent);
            _this.updateTotalPercent();
          },
        },
        function(err, data) {
          console.log("...", err, data);
          if (data.statusCode == 200) {
            console.log("..done..", data);
            console.log("..key..", data.Key); // cos key 在这
            cosApi.getCdnUrl(data.Key).then((ret) => {
              console.log(ret); // cdn url 在这
              if (ret.code == 0) {
                afile.key = data.Key;
                afile.url = ret.data;
                afile.icon = ret.data;
                afile.percent = 100;
                if (afile.fileType == "video") {
                  afile.icon = _this.videoUploadDoneIcon;
                } else if (afile.fileType == "audio") {
                  afile.icon = _this.audioUploadDoneIcon;
                } else if (afile.fileType == "doc") {
                  afile.icon = _this.docUploadDoneIcon;
                } else if (afile.fileType == "zip") {
                  afile.icon = _this.zipUploadDoneIcon;
                } else if (afile.fileType == "image") {
                  afile.icon = afile.url;
                }
                console.log("###", afile);
                cosApi
                  .setCosTmpFinish(afile.cosId)
                  .then((ret) => {
                    if (ret.code == 0 && ret.data) {
                      _this.onUploadDone();
                    }
                  })
                  .catch((err) => {});
              }
            });
          }
        }
      );
    },

    dogeUploadSingleVod(afile) {
      let _this = this;
      cosApi.getDogeCloudTmpToken(this.bizType, afile.fileName).then((ret) => {
        console.log("doge upload,...", ret);
        if (ret.code == 0) {
          var tmpToken = ret.data.tmpToken;
          let cosTmp = ret.data.cosTmp;

          afile.cosId = cosTmp.cosId;
          afile.cosKey = cosTmp.cosKey;
          afile.keyPrefix = cosTmp.keyPrefix;
          afile.fileType = cosTmp.fileType;
          afile.fileName = cosTmp.fileName;
          afile.cloudService = cosTmp.cloudService;
          afile.vid = cosTmp.vid;
          afile.vcode = cosTmp.vcode;
          afile.did = cosTmp.did;
          afile.key = cosTmp.cosKey;

          var s3 = new AWS.S3({
            // 用服务端返回的信息初始化一个 S3 实例
            region: "automatic",
            endpoint: tmpToken.vodUploadInfo.s3Endpoint,
            credentials: tmpToken.credentials,
            params: {
              Bucket: tmpToken.vodUploadInfo.s3Bucket,
            },
          });
          var s3Upload = s3
            .upload({
              Key: tmpToken.vodUploadInfo.key,
              Body: afile.file,
              ContentType: afile.file.type, // 设置上传后文件的 MIME 类型
            })
            .on("httpUploadProgress", function(evt) {
              var percent = ((evt.loaded * 100) / evt.total).toFixed(0);
              afile.percent = percent;
              //console.log("进度 : " + percent + "%");
              _this.updateTotalPercent();
            });
          s3Upload.send(function(err, data) {
            //document.getElementById("uploadBtn").disabled = false;
            if (err) {
              console.error("上传出错", err);
            } else {
              console.log("上传完成，正在确认");
              cosApi.getDogeCloudVodUploadDone(tmpToken.vodUploadInfo.did).then((ret) => {
                if (ret.code == 0) {
                  console.log("上传成功,", ret);
                  afile.key = "";
                  afile.url = "";
                  afile.icon = _this.videoUploadDoneIcon;
                  afile.percent = 100;
                  afile.vid = ret.data.vid;
                  afile.vcode = ret.data.vcode;
                  afile.did = tmpToken.vodUploadInfo.did;
                  cosApi
                    .setDogeCosTmpFinish(afile.cosId, afile.vid, afile.vcode, afile.did)
                    .then((ret) => {
                      if (ret.code == 0 && ret.data) {
                        _this.onUploadDone();
                      }
                    })
                    .catch((err) => {});
                }
              });
            }
          });
        }
      });
    },

    onUploadDone() {
      // 上传成功后，emit数据
      let allDone = true;
      for (let item of this.todoFiles) {
        if (item.percent < 100) {
          allDone = false;
          break;
        }
      }
      if (allDone) {
        let retList = [...this.attach];
        console.log("max size", this.maxSize);
        if (retList.length >= this.maxSize) {
          msgLogger.warn("最大上传数量：" + this.maxsize);
          console.log("emit", retList);
          this.$emit("onAddMedia", retList);
          this.$emit("update-attach", retList);
          this.isUploading = false;
          return;
        }
        for (let item of this.todoFiles) {
          retList.push({
            cosId: item.cosId,
            cosKey: item.cosKey,
            keyPrefix: item.keyPrefix,
            fileType: item.fileType,
            fileName: item.fileName,
            cloudService: item.cloudService, // 0 cos 1 doge
            vid: item.vid,
            vcode: item.vcode,
            did: item.did,
            percent: item.percent,
            url: item.url,
            key: item.cosKey,
            icon: item.icon,
          });
        }
        console.log("emit", retList);
        this.$emit("onAddMedia", retList);
        this.$emit("update-attach", retList);
        this.isUploading = false;
      }
    },
    updateTotalPercent() {
      // 简单实现进度
      if (this.isUploading) {
        let total = 0;
        let cur = 0;
        for (let item of this.todoFiles) {
          cur += item.percent;
          total += 100;
        }
        this.totalPercent = (cur / total) * 100;
        this.totalPercent = this.totalPercent.toFixed(0);
        this.totalPercent = this.totalPercent + "%";
      }
    },
    wrapAttachIcon(attachList) {
      let _this = this;
      if (attachList && attachList.length) {
        for (let afile of attachList) {
          if (afile.fileType == "video") {
            afile.icon = _this.videoUploadDoneIcon;
          } else if (afile.fileType == "audio") {
            afile.icon = _this.audioUploadDoneIcon;
          } else if (afile.fileType == "doc") {
            afile.icon = _this.docUploadDoneIcon;
          } else if (afile.fileType == "zip") {
            afile.icon = _this.zipUploadDoneIcon;
          } else if (afile.fileType == "image") {
            afile.icon = afile.url;
          }
        }
      }
    },
  },
  mounted() {
    this.getBucketRegion();
    this.getCosStsUrl();
    console.log("this.attach", this.attach);
  },
  watch: {
    attach: function(newVal, oldVal) {
      console.log("init watch", newVal);
      this.wrapAttachIcon(newVal);
    },
  },
  destroyed() {},
  filters: {},
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .img {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    position: relative; // 父节点要设置relative
    border-radius: 8px;
    overflow: hidden;
    img {
      height: 54px;
      width: 54px;
    }
    .del-icon {
      position: absolute; // 相对于 static 定位以外的第一个父元素进行定位,如果找不到父级元素，那么相对于浏览器窗口进行定位 。
      z-index: 1;
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
    }
    .file-name {
      position: absolute; // 相对于 static 定位以外的第一个父元素进行定位,如果找不到父级元素，那么相对于浏览器窗口进行定位 。
      z-index: 1;
      bottom: 0px;
      left: 0px;
      width: 54px;
      height: 12px;
      font-size: 10px;
      text-align: left;
      white-space: nowrap;
      padding: 5px;
      //background: rgba(0, 0, 0, 0);
    }
  }
  .add-image {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    img {
      height: 54px;
      width: 54px;
    }
  }
  .add-progress {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    background: #d8d8d8;
    border-radius: 8px;
    color: #505051;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
