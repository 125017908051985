import { Notify } from "vant";

let msg = {
  succeed: (text) => {
    Notify({ type: "success", message: text });
  },
  done: (text) => {
    Notify({ type: "success", message: text });
  },
  warn: (text) => {
    Notify({ type: "warning", message: text });
  },
  error: (text) => {
    Notify({ type: "danger", message: text });
  },
  info: (text) => {
    Notify({ type: "primary", message: text });
  },
};

export default msg;
